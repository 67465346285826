import React from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "components/Layout";
import "./Home.scss";

export default function Home({ location }) {
  const pathName = location.pathname.split("/")[1];

  return (
    <Layout pathName={pathName}>
      <Container fluid itemScope itemType="http://schema.org/Service">
        <div className="toms-pool">
          <StaticImage
            src="../../content/assets/images/toms_pool.jpg"
            width="500"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Roseville Pool Service"
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div className="intro">
          <h3>
            <span itemProp="name"> Roseville Pool Service </span>
            <small>
              - Swimming Pool Service in Roseville, Rocklin and Granite Bay, CA. Call{" "}
              <span>916-791-1221</span>.
            </small>
          </h3>
          <hr />
          <section itemProp="description">
            <p>We are a family operated business residing in Roseville, California.</p>
            <p>
              We offer exceptional quality weekly swimming pool maintenance and repair services,
              taking care of all your swimming pool needs or you can choose the service level that
              suits you best.
            </p>
            <p>
              You may find cheaper quotes, but no one will match the actual price per quality ratio
              that we deliver.
            </p>
            <p>
              We take pride in our work and we deliver results based on the service level you choose. We never cut
              corners and, in addition to the weekly service you are receiving, we have the expertise and knowledge 
              to detect problems before they become bigger. We will check
              to make sure your filter pump and pool cleaner are running properly to 
              keep your pool in good condition and to save you money in the long run.
              Typically, some other pool service providers may send out different people each week, and they may not have the expertise we have
              to detect issues with your pool equipment before they cause more problems.
            </p>
            <p>
              We are licensed, bonded and have liability insurance. Contractor&apos;s D35 license #
              is 1005209.
            </p>
            <p>We can provide you with references.</p>
            <p>We are currently servicing the following areas:</p>
            <span itemProp="areaServed">
              <ul className="service-area">
                <li>Pool Service in Roseville, zip code 95661, 95678, 95747</li>
                <li>Pool Service in Granite Bay, zip code 95746</li>
                <li>Pool Service in Rocklin, zip code 95765</li>
              </ul>
            </span>
          </section>
        </div>
      </Container>
    </Layout>
  );
}

Home.propTypes = {
  location: PropTypes.object,
};

Home.defaultProps = {
  location: null,
};
